.quick-access {
    /* Box Model */
    margin-top: 30px;
    padding:  0 16px;

    .desktop & {

        /* Box Model */
        margin-top: 0;
        padding:    0;
    }
}
.quick-access__list {
    /* Flexbox */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    &.left-align {

        .desktop & {
            justify-content: flex-start;
            .quick-access__item {
                margin-right: 20px;
            }
        }
    }

    /* Box Model */
    margin:  0;
    padding: 0;

    /* Other */
    list-style-type: none;
}
.quick-access__item--full-width {
    /* Box Model */
    width: 100%;

    .quick-access__button {
        /* Box Model */
        width: 100%;
    }
}
.quick-access__button {
    /* Variables */
    $button-padding: 15px;

    /**
    * Positioning
    * -----------
    * Adding a positioning context to place the button images
    *
    */
    position: relative;

    /* Flexbox */
    display: flex;
    flex-direction: row;
    align-items: center;

    /* Box Model */
    height: 44px;
    //width: 156px;
    width: calc(44vw);
    border-radius: 2px;
    margin-bottom: 16px;
    padding:     0 $button-padding;
    overflow: visible;

    /* Typography */
    text-align: left;

    /* Other */
    background-color: white;
    border: 1px solid #F0F0F0;

    span {
        /* Flexbox */
        flex: 3;

        /* Box Model */
        overflow: hidden;
        max-width: 85px;

        /* Typography */
        font-family: Satoshi, sans-serif;
        font-size: 12px;
        font-weight: bold;
        line-height: 16px;
        text-overflow: ellipsis;
        white-space: pre-wrap;
    }

    img {
        /* Variable */

        /* Positioning */
        position: absolute;
        right:  5px;
        bottom: 5px;

        /* Flexbox */
        // flex: 1;

        /* Box Model */
        max-height: 40px;
        max-width: 47px;

        .desktop & {
            /* Box Model */
            max-height: 80px;
            max-width: 95px;
        }
    }

    .desktop & {
        /* Flexbox */
        align-items: flex-start;

        /* Box Model */
        height:  70px;
        width:  205px;
        padding: 15px;
        margin-bottom: 30px;

        &:hover {
            border-color: $color--attention;
            color: $color--attention;
        }
    }
}