.cancelar-page {

  padding: 0 16px 100px 16px;
  background-color: $background-color--secondary;

  .mobile & {
    min-height: calc(100vh - #{$nav-height--mobile} - #{$status-height--mobile});
  }

  .fixed-CTA__leyenda {
    color: black;
  }

  .cancelar__detalle {
    margin-top: 28px;

    /* Typography */
    @extend %font-style--title;
    font-size: 20px;
    font-weight: 400;
    line-height: 31px;

    span {
      color: $color--attention;
    }

    .desktop & {
      font-size: 26px;
      max-width: 800px;
      margin: 28px auto;
    }

    .cancelar__detalle-subtitulo {
      color: inherit;
      line-height: 40px;
    }

    &.alerta {
      .cancelar__detalle-subtitulo {
        color: $color--attention;
      }

    }

    .detalle-usuario-asignado {
      font-size: 0.8em;
    }

  }

  .cancelar__aviso {
    margin-top: 12px;

    /* Typography */
    @extend %font-style--title;
    font-size: 10px;
    font-weight: 100;
    line-height: 14px;

    span {
      color: $color--attention;
    }

    .desktop & {
      font-size: 14px;
      max-width: 800px;
      margin: 18px auto;
    }

    .cancelar__detalle-subtitulo {
      color: inherit;
      line-height: 40px;
    }

    &.alerta {
      .cancelar__detalle-subtitulo {
        color: $color--attention;
      }

    }

  }

  .cancelar__controles-extra {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    // padding-bottom: 100px;
    .mobile & {
      height: 120px;
      justify-content: space-between;
      flex-direction: column;
    }
    .desktop & {
      flex-direction: row;
    }
  }

  .desktop & {
    padding: 0 24px 100px 24px;
    height: calc(100vh - #{$status-height--desktop});

    .fixed-CTA {
      left: $nav-width--desktop;
      bottom: 0;
      width: calc(100% - #{$nav-width--desktop});
    }
    .fixed-CTA__control {
      width: 300px;

    }
  }

  .fixed-CTA.sin-leyenda {
    .fixed-CTA__leyenda {
      display: none;
    }
    .mobile & {
      background: none;
      box-shadow: none;
      .fixed-CTA__control {
        width: 100%;
      }
    }
  }


  .wrapper--scrollable-content__layout {
    .desktop & {
        /* Flexbox */
        display: flex;
        flex-direction: row;
        justify-content: center;

        /* Box Model */
        padding: 30px 38px;
      }
  }
  .wrapper--scrollable-content__column {

    .titulo{

      display: none;

      .desktop & {
        display: block;

        /* Typography */
        text-transform: none;
        color: rgba(0, 0, 0, 0.87);
        opacity: 0.7;
        text-transform: uppercase;
        font-family: Satoshi, sans-serif;
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;


      }

    }

    &:first-child {
        .desktop & {
            /* Flexbox child */
            flex: 0 0 380px;
        }
    }

    &:last-child {
        .desktop & {
            /* Flexbox child */
            flex: 0 0 415px;

            /* Box Model */
            margin-left: 45px;
        }
    }

  }

}

.cancelar__subtitulo {
  max-width: 800px;
  margin: 28px auto;
  display: none;

  @extend %font-style--title;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-transform: uppercase;
  color: $background-color--tertiary;

  .desktop & {
    display: block;
  }
}