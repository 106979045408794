/* Variables */
$spacing: 16px;

.experiences-spotlight {
    /* Box Model */
    margin-top:  ( $spacing * 2 );
    padding: 0 $spacing;

    /* Typography */
    //font-family: 'Rubik', sans-serif;

    .desktop & {
        /* Box Model */
        padding: 0;
    }
}
.experiences-spotlight__title {
    /* Box Model */
    margin: 32px 0 0;

    /* Typograhpy */
    // font-family: Satoshi, sans-serif;
    font-family: Satoshi, sans-serif;
    font-size: 20px;
    line-height: 20px;
    text-transform: uppercase;

    &:first-child {
        /* Box Model */
        margin: 0;
    }
}

/**
* EXPERIENCES-LIST
* ----------------
* Changes to .experiences-list when located inside .experiences-spotlight
*
*/
.experiences-list {
    .experiences-spotlight & {
        .desktop & {
           /* Box Model */
           margin-top: 16px;
           width: 650px;
        }
    }
}
.experience {
    .experiences-spotlight & {
        .desktop & {
            /* Flexbox */
            flex: 0 0 313px;
            img.hover {
                display: none;
            }

            &:hover {
                cursor: pointer;
                .experience__details {
                    background-color: $background-color--attention;
                    .experience__date, .experience__time, .experience__location {
                        color: $color--inverted;
                        img {
                            display: none;
                            &.hover {
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }

    /**
    * MARGINS
    * -------
    * We remove the margin-left on the .experience that immediately follows
    * a .experience.is-highlighted
    *
    * We can't use a decendant selector after the adjacent sibling combinator
    * selector (i.e. '+') , but we *can* use it before. Thus we craft the
    * following selector.
    *
    */
    $highlighted-experiences-selector: '.desktop .experiences-spotlight .experience.is-highlighted';

    #{$highlighted-experiences-selector} + & {
        /* Box Model */
        margin-left: 0;
    }
}

.experience.is-highlighted {
    .experiences-spotlight & {
        font-size: 18px;
        line-height: 22px;
        font-weight: 400;

        .desktop & {
            /* Flexbox */
            flex: 0 0 650px;

            /* Box Model */
            margin-top:   0;
        }
        .experience__date {
            .mobile & {
                display: unset;
            }
        }
    }
}

.wrapper--experience__image {
    .experiences-spotlight & {
        .desktop & {
            /* Box Model */
            height: 150px;
        }
    }
}
.experience__image {
    .experiences-spotlight & {
        .desktop & {}
    }
}
.experience__details {
    .experiences-spotlight & {
        .desktop & {
        }
    }
}
.experiences-spotlight__controls {
    /* Flexbox */
    display: flex;
    flex-direction: row;
    justify-content: center;

    /* Box Model */
    margin-top: ( $spacing * 2 );
}
.experiences-spotlight__more-experiences {
    @extend %button--no-background;

    /* Box Model */
    margin:  0;
    padding: 0;

    /* Typography */
    color: $color--attention;
    text-decoration: none;
    font-size:   14px;
    line-height: 17px;
    text-align: center;

    i {
        /* Box Model */
        margin-left: ( $spacing / 2 );

        /* Typography */
        font-size: 9px;
        line-height: 17px;
        vertical-align: bottom; // DEBUG DEBUG Magic Number?? DEBUG DEBUG
    }
}