$padding-lateral: 16px;
$padding-lateral-desktop: 24px;
$column-width: 100%;
$column-width-desktop: calc(((100% - #{$nav-width--desktop}) / 1.8) - #{$padding-lateral-desktop});
$padding-top: 0px;
$margin-bottom: 36px;

.detalle-de-experiencia {
    padding: 0 $padding-lateral 100px $padding-lateral;
    //background-color: #FEFEFE;

    .desktop & {
        padding: 0 $padding-lateral-desktop 100px $padding-lateral-desktop;
    }

    .fixed-CTA__leyenda {
        color: black;
    }

/*
    .desktop & .fixed-CTA {
        left: $nav-width--desktop;
        bottom: 0;
        width: calc(100% - #{$nav-width--desktop});
    }
*/
    .mobile & .fixed-CTA {
        &.cancelar {
            .fixed-CTA__control {
                width: 100%;
            }
        }
    }

    .desktop & .fixed-CTA {
        position: absolute;
        left: auto;
        right: $padding-lateral-desktop;
        top: calc(#{$titulo-back-height--desktop} + #{$padding-top} + #{$margin-bottom} + 12px); //12px es para una correccion
        width: calc(100% - #{$column-width-desktop} - (#{$padding-lateral-desktop} * 4));
        max-width: 360px;
        min-width: 250px;
        height: 250px;

        box-shadow: none;

        flex-direction: column;
        align-items: flex-start;

        .fixed-CTA__titulo {
            font-family: Satoshi;
            font-size: 20px;
            font-weight: 400;

            letter-spacing: normal;

            text-transform: none;
        }

        .fixed-CTA__leyenda {
            visibility: hidden;
        }

        .fixed-CTA__control {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
        }
        .detalle__icon {
            min-width: 16px;
        }

        .detalle__texto {
            margin-left: 16px;
        }

        .detalle-de-experiencia__duracion {
            margin-bottom: 16px;

            .detalle__texto {
                margin-left: 28px;
            }
        }

    }

    .titulo-back {
        justify-content: space-between;
        .desktop & {
            justify-content: space-between;
            width: calc(100vw - #{$nav-width--desktop});
            //height: 150px;
            left: -$padding-lateral-desktop;
            //background-color: white;
            padding-right: $padding-lateral-desktop;
            align-items: flex-end;
        }

    }

    .titulo-back__control {
        .desktop & {
            left: $padding-lateral-desktop;
        }
    }

    .titulo-back__titulo {
        visibility: hidden;
        .desktop & {
            @extend %detalle-de-experiencia__titulo;
            visibility: visible;
        }
    }
}

.detalle-de-experiencia__FixedCTA-info {
    display: none;
    font-size: 14px;

    .desktop & {
        display: block;
    }
}

.detalle-de-experiencia__icons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: max-content;

    .detalle .detalle__texto {
        display: none;
    }

    .desktop & .detalle {
        width: max-content;

        .detalle__texto {
            display: inline-block;
            margin-left: 16px;
        }
    }

    button {
        @extend %button--no-background;
        @extend %button--icon-only;
    }
    .detalle-de-experiencia__control {
        margin-left: 24px;
    }
}

%detalle-de-experiencia__titulo {
    font-family: Satoshi;
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
}

.detalle-de-experiencia__titulo {
    @extend %detalle-de-experiencia__titulo;
    .desktop & {
        visibility: hidden;
    }
}

.detalle-de-experiencia__info {
    margin-bottom: $margin-bottom;

    & .detalle {

        .detalle__icon {
            min-width: 16px;
        }

        .detalle__texto {
            margin-left: 16px;
        }

        &.detalle-de-experiencia__duracion {
            margin-bottom: 16px;

            .detalle__texto {
                margin-left: 28px;
            }
        }
    }

    .desktop & {
        visibility: hidden;
        height: $padding-top;
    }
}

.detalle-de-experiencia__showcase {
    width: 100%;
}

.detalle_de_experiencia__imagen-wrapper {
    width: calc(100% + (#{$padding-lateral} * 2));
    position: relative;
    left: -$padding-lateral;
    overflow: hidden;
    max-height: 200px;

    margin-bottom: $margin-bottom;

    display: flex;
    justify-content: center;
    align-items: center;

    .desktop & {
        left: auto;
        width: calc(#{$column-width-desktop} * 1.25);
        max-height: 350px;
    }
}

.detalle_de_experiencia__imagen {
    width: 100%;
}

.detalle-de-experiencia__asistentes {
    .maquinero {
        margin-right: 24px;
    }
    .maquinero-card__aptitudes {
        //display: none;
    }
}

.detalle-de-experiencia__asistentes__titulo,
.detalle-de-experiencia__subtitulo {
    font-family: Satoshi;
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
}

.detalle-de-experiencia__detalles {
    width: $column-width;
    margin-bottom: $margin-bottom;

    font-size: 14px;
    line-height: 21px;
    font-weight: 400;

    .desktop & {
        width: $column-width-desktop;
    }
}

.detalle-de-experiencia__link {
    //font-size: 12px;
    max-width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    a {
        color: $color--attention;
    }

    .desktop & {
        max-width: 330px;
    }
}

.detalle-de-experiencia__linkPrivado {
    //font-size: 12px;
    max-width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    a {
        color: $color--attention;
    }

    .icon--chevron {
        transform: rotate(180deg);
    }

    .desktop & {
        max-width: 330px;
    }
}