$padding-lateral: 16px;
$padding-lateral-desktop: 24px;
$column-width: 100%;
$column-width-desktop: calc(((100% - #{$nav-width--desktop}) / 1.8) - #{$padding-lateral-desktop});
$padding-top: 0px;
$margin-bottom: 36px;

.detalle-de-maquinero {
    padding: 0 $padding-lateral 100px $padding-lateral;
    //background-color: #FEFEFE;
    color: $background-color--tertiary;

    .desktop & {
        padding: 0 $padding-lateral-desktop 100px $padding-lateral-desktop;
        max-width: 800px;
    }

    .fixed-CTA__leyenda {
        color: black;
    }

/*
    .desktop & .fixed-CTA {
        left: $nav-width--desktop;
        bottom: 0;
        width: calc(100% - #{$nav-width--desktop});
    }
*/
    .mobile & .fixed-CTA {
        &.cancelar {
            .fixed-CTA__control {
                width: 100%;
            }
        }
    }

    .desktop & .fixed-CTA {
        position: absolute;
        left: auto;
        right: $padding-lateral-desktop;
        top: calc(#{$titulo-back-height--desktop} + #{$padding-top} + #{$margin-bottom} + 12px); //12px es para una correccion
        width: calc(100% - #{$column-width-desktop} - (#{$padding-lateral-desktop} * 4));
        max-width: 360px;
        min-width: 250px;
        height: 250px;

        box-shadow: none;

        flex-direction: column;
        align-items: flex-start;

        .fixed-CTA__titulo {
            font-family: Satoshi;
            font-size: 20px;
            font-weight: 400;

            letter-spacing: normal;

            text-transform: none;
        }

        .fixed-CTA__leyenda {
            visibility: hidden;
        }

        .fixed-CTA__control {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
        }
        .detalle__icon {
            min-width: 16px;
        }

        .detalle__texto {
            margin-left: 16px;
        }

        .detalle-de-maquinero__duracion {
            margin-bottom: 16px;

            .detalle__texto {
                margin-left: 28px;
            }
        }

    }

    .titulo-back {
        .desktop & {
            justify-content: space-between;
            width: calc(100vw - #{$nav-width--desktop});
            //height: 150px;
            left: -$padding-lateral-desktop;
            //background-color: white;
            padding-right: $padding-lateral-desktop;
            align-items: flex-end;
        }

    }


}

.detalle-de-maquinero__main {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 160px;

    .mobile & {
        flex-direction: column;
        height: max-content;
    }

    .desktop & {
        //margin-top: 70px;

        .maquinero-card__avatar {
            margin-right: 31px;
        }
    }

    .datos {

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .mobile & {
            align-items: center;
        }
    }
}

.detalle-de-maquinero__nombre {
    @extend %font-style--attention;
    color: $color--primary;
    font-size: 16px;
    line-height: 24px;
}

.detalle-de-maquinero__fecha {
    display: block;
    margin-bottom: $margin-bottom;
    font-size: 12px;
}

.detalle-de-maquinero__aptitudes {
    font-size: 14px;
}

%detalle-de-maquinero__titulo {
    font-family: Satoshi;
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
}

.detalle-de-maquinero__titulo {
    @extend %detalle-de-maquinero__titulo;
    .desktop & {
        visibility: hidden;
    }
}

.detalle-de-maquinero__info {
    margin-bottom: $margin-bottom;
    font-size: 14px;
    line-height: 21px;

}

.detalle-de-maquinero__contactar {
    display: flex;
    flex-direction: column;
    position: relative;
}

.detalle-de-maquinero__contactar-form {
    .desktop & {
        display: flex;
    }
}

.detalle-de-maquinero__contactar-label {
    color: $color--primary;
}

.detalle-de-maquinero__contactar-input {
    padding: 6px;
    .desktop & {
        margin-right: 12px;
    }
}

.detalle-de-maquinero__contactar-button {
    @extend %button--icon-only;
    @extend %button--primary;

    .mobile & {
        margin-top: 14px;
        width: 100%;
    }
}

.detalle-de-maquinero__notification {
    width: 100%;
    display: flex;
    justify-content: space-between;

    background-color: $background-color--exito;
    color: $color--inverted;

    padding: 16px;
    border-radius: 2px;

    position: absolute;

    .desktop & {
        cursor: pointer;
    }
}

.detalle-de-maquinero__notification-button {
    border: none;
    background: none;
    color: inherit;

}
