/* Variables */
$spacing:     16px;
$sideSpacing: 38px;

.experiences-showcase {
    /* Box Model */
    margin-top:  ( $spacing * 2 );
    padding: ( $spacing * 2 ) $spacing;

    /* Other */
    background-image: linear-gradient(
        180deg,
        #B0ADD7 0%,
        rgba(191, 186, 218, 0.807292) 0.01%,
        #FFFFFF 100%
        );

    .desktop & {
        /* Box Model */
        padding: ( $spacing * 2 ) $sideSpacing;
    }
}
.experiences-showcase__title {
    /* Box Model */
    margin: 0;

    /* Typograhpy */
    // font-family: Satoshi, sans-serif;
    font-family: Satoshi, sans-serif;
    font-size: 21px;
    font-weight: 400;
    line-height: 25px;
}
.experiences-list {
    .experiences-showcase & {
        /* Box Model */
        margin-top: 8px;

        .desktop & {
            /* Box Model */
            margin-top: 15px;
        }
    }
}
.experience {
    .experiences-showcase & {
        .desktop & {
            /* Flexbox Child */
            flex: 0 0 265px;
            img.hover {
                display: none;
            }

            &:hover {
                cursor: pointer;
                .experience__details {
                    background-color: $background-color--attention;
                    .experience__date, .experience__time, .experience__location {
                        color: $color--inverted;
                        img {
                            display: none;
                            &.hover {
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }
}
.experiences-showcase__controls {
    /* Flexbox */
    display: flex;
    flex-direction: row;
    justify-content: center;

    /* Box Model */
    margin-top: ( $spacing * 2 );
}
.experiences-showcase__more-experiences {
    @extend %button--no-background;

    /* Box Model */
    margin:  0;
    padding: 0;

    /* Typography */
    color: $color--attention;
    text-decoration: none;
    font-size:   14px;
    line-height: 17px;
    text-align: center;

    i {
        /* Box Model */
        margin-left: ( $spacing / 2 );

        /* Typography */
        font-size: 9px;
        line-height: 17px;
        vertical-align: bottom; // DEBUG DEBUG Magic Number?? DEBUG DEBUG
    }
}