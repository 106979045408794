.confirmar-page {

  padding: 0 16px 100px 16px;
  background-color: $background-color--secondary;

  .mobile & {
    min-height: calc(100vh - #{$nav-height--mobile} - #{$status-height--mobile});
  }

  .fixed-CTA__leyenda {
    color: black;
  }

  .confirmar__detalle {
    margin-top: 28px;

    /* Typography */
    @extend %font-style--title;
    font-size: 20px;
    font-weight: 400;
    line-height: 31px;

    span {
      color: $color--attention;
    }

    .desktop & {
      font-size: 24px;
      max-width: 800px;
      margin: 28px auto;

      //text-align: center;
    }

  }

  .confirmar__ddjj {
    //@extend %button;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    width: 100%;

    margin-top: 16px;
    padding: 10px;

    font-size: 14px;

    border: 1px solid $background-color--attention;
    border-radius: 2px;
    background-color: $background-color--secondary;
    color: $color--attention;

    outline: none;

    .confirmar__ddjj-icon {
      border-style: solid;
      border-width: 3px;
      border-radius: 50%;
      padding: 8px;

      &::before {
        visibility: hidden;
      }
    }

    .desktop &:hover {
      background-color: $background-color--attention;
      color: $color--inverted;

      &.firmada {
        background-color: $background-color--attention;
        color: $color--primary;
      }
    }

    &.firmada {
      background-color: $background-color--attention;
      color: $color--inverted;
      .confirmar__ddjj-icon::before {
        visibility: visible;
      }
    }

    .confirmar__ddjj-text {
      margin: 0;
      margin-top: 1px;
    }
  }

  .desktop & {
    padding: 0 24px 100px 24px;
    //height: calc(100vh - #{$status-height--desktop});

    .fixed-CTA {
      left: $nav-width--desktop;
      bottom: 0;
      width: calc(100% - #{$nav-width--desktop});
    }

  }

  .fixed-CTA.sin-leyenda {
    .fixed-CTA__leyenda {
      display: none;
    }
    .mobile & {
      background: none;
      box-shadow: none;
      .fixed-CTA__control {
        width: 100%;
      }
    }
  }


  .wrapper--scrollable-content__layout {
    .desktop & {
        /* Flexbox */
        display: flex;
        flex-direction: row;
        justify-content: center;

        /* Box Model */
        padding: 30px 38px;
      }
  }
  .wrapper--scrollable-content__column {

    .titulo{

      display: none;

      .desktop & {
        display: block;

        /* Typography */
        text-transform: none;
        color: rgba(0, 0, 0, 0.87);
        opacity: 0.7;
        text-transform: uppercase;
        font-family: Satoshi, sans-serif;
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;


      }

    }

    &:first-child {
        .desktop & {
            /* Flexbox child */
            flex: 0 0 380px;
        }
    }

    &:last-child {
        .desktop & {
            /* Flexbox child */
            flex: 0 0 415px;

            /* Box Model */
            margin-left: 45px;
        }
    }

  }

}

.confirmar__subtitulo {
  max-width: 800px;
  margin: 28px auto;
  display: none;

  @extend %font-style--title;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-transform: uppercase;
  color: $background-color--tertiary;

  .desktop & {
    display: block;
  }
}