$padding-lateral: 16px;
$padding-lateral-desktop: 24px;
$column-width: 100%;
$column-width-desktop: calc(((100% - #{$nav-width--desktop}) / 1.8) - #{$padding-lateral-desktop});
$padding-top: 0px;
$margin-bottom: 36px;

.detalle-de-beneficio {
    padding: 0 $padding-lateral 100px $padding-lateral;
    //background-color: #FEFEFE;

    .desktop & {
        padding: 0 $padding-lateral-desktop 100px $padding-lateral-desktop;
    }

    .fixed-CTA__leyenda {
        color: black;
    }

/*
    .desktop & .fixed-CTA {
        left: $nav-width--desktop;
        bottom: 0;
        width: calc(100% - #{$nav-width--desktop});
    }
*/
    .mobile & .fixed-CTA {
        &.cancelar {
            .fixed-CTA__control {
                width: 100%;
            }
        }
    }

    .desktop & .fixed-CTA {
        position: absolute;
        left: auto;
        right: $padding-lateral-desktop;
        top: calc(#{$titulo-back-height--desktop} + #{$padding-top} + #{$margin-bottom} + 12px); //12px es para una correccion
        width: calc(100% - #{$column-width-desktop} - (#{$padding-lateral-desktop} * 4));
        max-width: 360px;
        min-width: 250px;
        height: 250px;

        box-shadow: none;

        flex-direction: column;
        align-items: flex-start;

        .fixed-CTA__titulo {
            font-family: Satoshi;
            font-size: 20px;
            font-weight: 400;

            letter-spacing: normal;

            text-transform: none;
        }

        .fixed-CTA__leyenda {
            visibility: hidden;
        }

        .fixed-CTA__control {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
        }
        .detalle__icon {
            min-width: 16px;
        }

        .detalle__texto {
            margin-left: 16px;
        }

        .detalle-de-beneficio__duracion {
            margin-bottom: 16px;

            .detalle__texto {
                margin-left: 28px;
            }
        }

    }
}

.titulo-back__titulo {
    .desktop .detalle-de-beneficio & {
        visibility: hidden;
    }
}

.detalle-de-beneficio__FixedCTA-info {
    display: none;
    font-size: 14px;

    .desktop & {
        display: block;
    }
}

.detalle-de-beneficio__icons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: max-content;

    .detalle .detalle__texto {
        display: none;
    }

    .desktop & .detalle {
        width: max-content;

        .detalle__texto {
            display: inline-block;
            margin-left: 16px;
        }
    }

    button {
        @extend %button--no-background;
        @extend %button--icon-only;
    }
    .detalle-de-beneficio__control {
        margin-left: 24px;
    }
}

.detalle-de-beneficio__titulo {
    // Box Model
    margin-block-end: 8px;
    // Typography
    font-family: Satoshi;
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
}

.detalle-de-beneficio__descripcion {
  // Box Model
  max-width: 35rem; // Setting the text's measure
  // Typography
  white-space: pre-wrap;
}

.detalle-de-beneficio__info {
    margin-bottom: $margin-bottom;

    & .detalle {

        .detalle__icon {
            min-width: 16px;
        }

        .detalle__texto {
            margin-left: 16px;
        }

        &.detalle-de-beneficio__duracion {
            margin-bottom: 16px;

            .detalle__texto {
                margin-left: 28px;
            }
        }
    }

    /* .desktop & {
        visibility: hidden;
        height: $padding-top;
    } */
}

.detalle-de-beneficio__descuento,
.detalle-de-beneficio__ubicacion {
    // Flexbox
    display: flex;
    align-items: center;
}
.icon--info,
.icon--map-pin {
    .detalle-de-beneficio__info & {
        // Box Model
        height: 16px;
    }
}
.detalle__texto {
    .detalle-de-beneficio__descuento &,
    .detalle-de-beneficio__ubicacion & {
        // Typography
        font-size: 20px;
    }
}

.detalle-de-beneficio__showcase {
    width: 100%;
}

.detalle_de_beneficio__imagen-wrapper {
    // Box Model
    margin-bottom: $margin-bottom;
    width: 100%;
    height: 200px;
    // Flexbox
    display: flex;
    justify-content: center;
    align-items: center;
    // Background-Image
    background-repeat  : no-repeat;
    background-size    : contain;
    background-position: center;

    .desktop & {
        height: 350px;
    }
}

.detalle_de_beneficio__imagen {
    width: 100%;
}

.detalle-de-beneficio__asistentes {
    .maquinero {
        margin-right: 24px;
    }
    .maquinero-card__aptitudes {
        //display: none;
    }
}

.detalle-de-beneficio__subtitulo {
    // Typography
    font-family: Satoshi;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    text-transform: uppercase;
}

.detalle-de-beneficio__detalles {
    // width: $column-width;
    margin-bottom: $margin-bottom;
    // Typography
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    // Box Model
    // width: 40rem;
    max-width: 100%;

    .desktop & {
        // Typography
        font-size: 18px;
        line-height: 22px;
    }
}

.detalle-de-beneficio__link {
    //font-size: 12px;
    max-width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    a {
        color: $color--attention;
    }

    .desktop & {
        max-width: 330px;
    }
}

.detalle-de-beneficio__linkPrivado {
    //font-size: 12px;
    max-width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    a {
        color: $color--attention;
    }

    .icon--chevron {
        transform: rotate(180deg);
    }

    .desktop & {
        max-width: 330px;
    }
}

.control--link-web {
    @extend %button--primary;
    // Box Model
    margin-block-start: 16px;
    width: 100%;
    .desktop & {
      // Box Model
      width: 200px;
    }
}
// TO-DO: Order properly on page

.wrapper--scrollable-content {
    .detalle-de-beneficio & {
      .desktop & {
        // Box Model
        max-width: 1000px;
        margin-inline: auto;
        // Flexbox
        display        : flex;
        flex-direction : column;
        align-items    : center;
        justify-content: flex-start;
      }
    }
  }
.scrollable-content__row {
  .detalle-de-beneficio & {
    // Flexbox
    display        : flex;
    flex-direction : column;
    justify-content: flex-start;
    // Box Model
    width: 100%;

    .desktop & {
      // Flexbox
      flex-direction : row;
      justify-content: center;
    }

    &.image-row {
      // Box Model
      margin-block-end: 36px;
      padding-block-end: 12px;
      // Other
      border-bottom: 1px solid #bbb;
    }
  }
}
.scrollable-content__column {
  .detalle-de-beneficio & {
    // Flexbox
    flex: 1;

    &.column--primary {
      // Flexbox
      flex: 2;
    }
    // &.column--secondary { }
  }
}