.titulo-back {
  /* Box Model */
  height: $titulo-back-height--mobile;
  width: 100%;
  padding: 0;

  /* Position */
  position: relative;

  /* Other */
  background: none;
  border: none;

  /* Flex box */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .desktop & {
    /* Box Model */
    height: $titulo-back-height--desktop;

    //padding: 0 24px;

    /* Flex box */
    //justify-content: flex-start;
    justify-content: center; // Pedido por Nico 2021-03-17
    align-items: flex-end;

  }

}

.titulo-back__control {
  @extend %button--no-background;
  @extend %button--icon-only;

  /* Box Model */
  height: 100%;
  width: 50px;

  /* Flex box */
  display: flex;
  justify-content: flex-start;
  align-items: center;

  /* Position */
  position: absolute;
  top: 0;
  left: 0;

  .titulo-back__control-text {
    display: none;
  }

  &:focus {
    outline: none;
  }

  .desktop & { // Cambio a pedido de Nico 2021-03-17
    display: none;
    .titulo-back__control-text {
      display: block;

    }

  }
}

.titulo-back__icon {
  height: 16px;
  width: 16px;

  .desktop & {
    margin-top: -5px; // Para alinear con el titulo (imagen de flecha hacia la izquierda)
    display: none;
  }
}

.titulo-back__titulo {
  /* Box Model */
  padding: 0;
  margin: 0;
  max-width: calc(100% - 50px); // Para no pisar la flecha back

  /* Typography */
  color: black;
  font-size: 12px;
  line-height: 18px;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;

  .desktop & {
    /* Box Model */
    //margin-left: 36px; Pedido por Nico 2021-03-17
    margin-left: 24px;

    /* Typography */
    font-family: Satoshi, sans-serif;
    font-size: 24px;
    line-height: 28px;
    font-weight: 400;
    text-transform: none;
  }

}
