.menu-cover {
  position: relative;
  padding: 24px 16px;
  height: #{$menu-cover-height};
}

.menu-cover__back {
  position: absolute;
  width: 29px;
  height: 29px;
  right: 24px;
  top: 34px;
  background: rgba(25, 26, 26, 0.2);
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  outline: none;

  &:focus {
    outline: none;
  }

  img {
    position: relative;
    left: -1px;
  }

}

.menu-cover__text {
  font-size: 12px;
}

.menu-cover__title {
  /* Box Model */

  /* Typograhpy */
  // font-family: Satoshi, sans-serif;
  font-family: Satoshi, sans-serif;
  font-size: 18px;
  line-height: 22px;
  color:  $color--attention;
  margin: 0;
  font-weight: 700;

}