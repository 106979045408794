.menu-perfilCTA {
  position: relative;
  padding: 15px;
  background: rgba(241, 241, 241, 1);
  margin: 0 16px;
  border-radius: 5px;
  height: 137px;
}

.menu-perfilCTA__text {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 0;
}

.menu-perfilCTA__title {
  /* Box Model */

  /* Typograhpy */
  // font-family: Neue Haas, sans-serif;
  font-family: Satoshi, sans-serif;
  font-size: 14px;
  line-height: 21px;

}

.menu-perfilCTA__progress {
  .menu-perfilCTA & {
    width: 100%;
    height: 12px;
    // background: rgba(246, 105, 29, 0.5);
    border-radius: 6px;
    position: relative;
    margin: 10px 0;
  }

  &.progress--30 {
    &::after {
      width: 30%;
    }
  }

  &.progress--50 {
    &::after {
      width: 50%;
    }
  }

  &.progress--70 {
    &::after {
      width: 70%;
    }
  }

  &.progress--100 {
    &::after {
      width: 100%;
    }
  }

  &::after {
    content: '';
    display: block;
    height: 100%;
    width: 0;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(246, 105, 29, 1);
    border-radius: 6px;

  }
}