.status-bar {
    /* Variables */
    $boxShadowColor: rgba($color: #000, $alpha: 0.3);
    $sidePadding: 17px;

    /*
    * Flexbox
    * -------
    * Setting up a flexbox container to host the .status-bar__links, then:
    *
    * 1. The list is positioned towards the right of the container
    * 2. The links are laid out in a row
    * 3. The row of links is vertically centered on the .status-bar
    *
    */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    /* Box Model */
    box-sizing: border-box;
    padding: 0 $sidePadding;

    /*
    * Logo
    * -----
    *
    * 1. We're placing the MQCO logo via background-image
    * 2. We position it a distance equal to $sidePadding from the left
    * 3. We center it vertically
    * 4. We make sure the logo does not repeat
    *
    */
    background:
        url($status-bar__logo)
        $sidePadding center
        no-repeat;
    background-size: auto 22px;

    /* Other */
    background-color: white;
    box-shadow: 0 1px 5px 0 $boxShadowColor;

    .status-bar__logo {
        height: 22px;
        width: calc(100% - #{$nav-width--desktop});
        background:
            url($status-bar__logo)
            $sidePadding center
            no-repeat;
        background-size: auto 22px;
        background-position-x: center;

    }

    .desktop & {
        /*
        * Logo
        * -----
        * Centering the logo on desktop viewports
        *
        */
        background-image: none;
        background-position-x: center;

    }
}
.status-bar__controls {
    /* Flexbox
    ...
    *  Setting up each control to be displayed in a row.
    */
    display: flex;
    flex-direction: row;
    gap: 10px;

    /* Box Model */
    margin:  0;
    padding: 0;

    /* Other */
    list-style-type: none;

    .desktop & {
        /*
        * Box Model
        * ---------
        * Hiding the controls on desktop viewports.
        *
        */
        display: none;
    }
}
.status-bar__control-button {

    &.button--icon-only {
        /* Variables */
        $spacing: 17px;

        /* Font Awesome Rules */
        font-size: 20px;

        /**
        * Spacing
        * ---------
        * We need to add a visual separation between each of these buttons.
        * To that effect, we:
        *
        * 1. Define the desired spacing on the $spacing variable.
        * 2. Add a margin-left to each button equal to the value of
        *    $spacing.
        * 3. Remove the (redundant) margin on the first button, by setting
        *    the value of the margin-left rule of the button on the
        *    first <li> of the list to zero.
        *
        */
        margin-left: $spacing;

        li:first-child & { margin-left: 0; }
    }

    .icon--QR {
        background-color: white;
    }
}