.posicion-page {

  padding: 0 16px 100px 16px;

  .calendario {
    margin-top: 16px;
    padding-bottom: 24px;
    margin-bottom: 12px;
    width: 100%;

    .mobile & {
      width: 100%;
    }
  }

  .selector__wrapper {
    margin-bottom: 12px;
    width: 100%;

    .mobile & {
      width: 100%;
    }
  }

  .reservaDePosicion-turno {
    margin-top: 16px;

    .mobile & {
      width: 100%;
    }
  }

  .fixed-CTA__leyenda {
    color: black;
  }

  .desktop & {
    padding: 0 24px 100px 24px;

    .fixed-CTA {
      left: $nav-width--desktop;
      bottom: 0;
      width: calc(100% - #{$nav-width--desktop});
    }

  }

  .wrapper--scrollable-content__layout {
    .desktop & {
        /* Flexbox */
        display: flex;
        flex-direction: row;
        justify-content: center;

        /* Box Model */
        padding: 30px 38px;
        padding-top: 40px;
      }
  }
  .wrapper--scrollable-content__column {

    .titulo{

      display: none;

      .desktop & {
        display: block;

        /* Typography */
        text-transform: none;
        color: rgba(0, 0, 0, 0.87);
        opacity: 0.7;
        text-transform: uppercase;
        font-family: Satoshi, sans-serif;
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;


      }

    }

    &:first-child {
        .desktop & {
            /* Flexbox child */
            flex: 0 0 380px;
            max-width: 360px;
            .titulo {
              margin-bottom: 16px;
            }
        }
    }

    &:last-child {
        .desktop & {
            /* Flexbox child */
            flex: 0 0 415px;

            /* Box Model */
            margin-left: 25px;
        }
    }

  }

}