.onboarding-page {

  padding: 0 16px 100px 16px;

  .calendario {
    margin-top: 16px;
    padding-bottom: 24px;
    margin-bottom: 12px;

    .mobile & {
      width: 100%;
    }
  }

  .selector__wrapper {
    margin-bottom: 12px;

    .mobile & {
      width: 100%;
    }
  }

  .fixed-CTA__leyenda {
    color: black;
  }

  .desktop & {
    padding: 0 24px 100px 24px;

    .fixed-CTA {
      left: $nav-width--desktop;
      bottom: 0;
      width: calc(100% - #{$nav-width--desktop});
    }

  }

  .wrapper--scrollable-content__layout {
    .desktop & {
        /* Flexbox */
        display: flex;
        flex-direction: row;
        justify-content: center;

        /* Box Model */
        padding: 30px 38px;
      }
  }
  .wrapper--scrollable-content__column {

    .titulo{

      display: none;

      .desktop & {
        display: block;

        /* Typography */
        text-transform: none;
        color: rgba(0, 0, 0, 0.87);
        opacity: 0.7;
        text-transform: uppercase;
        font-family: Satoshi, sans-serif;
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;


      }

    }

    &:first-child {
        .desktop & {
            /* Flexbox child */
            flex: 0 0 380px;
        }
    }

    &:last-child {
        .desktop & {
            /* Flexbox child */
            flex: 0 0 415px;

            /* Box Model */
            margin-left: 45px;
        }
    }

  }

  .titulo {
    margin-top: 24px;

    font-size: 18px;
    font-weight: 700;

    .desktop & {
      @extend %font-style--title;
      margin-top: 48px;
      font-weight: 400;
      font-size: 20px;
    }

  }

  .membresias-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;

    .desktop & {
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;

    }
  }

  .membresias-list-item {
    margin-bottom: 14px;

    .check-multitexto {
      /* Box Model */
      height: 144px;
      width: 328px;
      padding: 14px;

      /* Other */
      background-color: white;
      border: none;
      border-radius: 2px;
      box-shadow: inset 0 0 0 1px #919eab;
      color: #919eab;
      outline: none;

      /* Flex box */
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      &.is-checked {
        /* Other */
        box-shadow: inset 0 0 0 1px $background-color--attention, 0 4px 8px 0 #dfe3e8;
      }

      &:focus {
        outline: none;
      }

      .mobile & {
        width: 100%;
      }

    }

    .check-multitexto-textos {
      height: auto;
      flex: 1;
      padding-right: 10px;
    }

    .check-multitexto-textos__titulo {
      color: $color--attention;
      font-family: inherit;
      font-weight: 400;

    }

    .check-multitexto-textos__descripcion {
      margin-top: 14px;
      font-size: 20px;
      //font-family: Satoshi;
      //font-size: 16px;
      font-weight: 400;
      color: $background-color--tertiary;
    }

    .check-multitexto-icon {
      height: auto;
      flex: 0;
    }

    .desktop & {
      margin-right: 14px;
    }
  }

}