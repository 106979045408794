.info-de-salas-page {
  padding: 0 16px 100px 16px;

  .calendario {
    margin-top: 16px;
    padding: 1px 0;
    margin-bottom: 12px;
    width: 360px; // Agregado a pedido de Nico 2021-03-10
    // Se agrega definicion de margenes especiales para el calendario en esta pagina

    .mobile & {
      width: 100%;
    }
  }

  .selector__wrapper {
    margin-bottom: 12px;
    width: 360px; // Agregado a pedido de Nico 2021-03-10

    .mobile & {
      width: 100%;
    }
    .info {
      width: 50px; // Agregado a pedido de Nico 2021-03-26 (para creditos/hora)
    }
  }

  .fixed-CTA__leyenda {
    color: black;
  }

  .desktop & {
    padding: 0 24px 100px 24px;

    .fixed-CTA {
      left: $nav-width--desktop;
      bottom: 0;
      width: calc(100% - #{$nav-width--desktop});
    }

  }


}
/*
.titulo{
  display: none;

  .desktop & {
    // Box Model
    display: block;
    // Typography
    text-transform: none;
    color: rgba(0, 0, 0, 0.87);
    opacity: 0.7;
    text-transform: uppercase;
    font-family: Satoshi, sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 16px;
  }
  }
*/
.wrapper--scrollable-content__layout {
  .info-de-salas-page & {
    .desktop & {
      // Flexbox
      display        : flex;
      flex-direction : row;
      justify-content: center;
      // Box Model
      padding: 30px 38px;
    }
  }
}
.wrapper--scrollable-content__column {
  .info-de-salas-page &:first-child {
    .desktop & {
        // Flexbox
        // flex: 0 0 380px;
        flex: 1;
    }
  }
}

.reserva-de-sala__horario-duracion {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  .selector__icon {
    display: none;
  }

  .selector-combo {
    width: 45%;
  }

  .selector__titulo {
    margin-left: 10px;
  }

  .selector__wrapper {
    max-width: 100%;
  }
}

.selector-combo__titulo {
  .reserva-de-sala-page & {
    // Typography
    color: $color--secondary;
    font-size: 12px;
    line-height: 18px;
  }
}

.control--info-de-salas {
  @extend %button--no-background;

  // Typography
  color: $color--attention;
  text-decoration: underline;
}

.reserva-de-sala__info-sala {

  /* Positioning */
  margin: -12px 0 0;

  /* Typography */
  font-size: 12px;
  line-height: 14px;
  text-align: right;

  /* Other */
  color: $color--attention;
  cursor: pointer;


}

.reserva-de-sala__info-sala-button {
  /* Box Model */
  height: 20px;
  width : 20px;

  text-align: center;

  /* Other */
  color           : $color--attention;
  background-color: $background-color--base;
  border-color    : $color--attention;
  border-radius   : 50%;
  box-shadow      : none;
  border-style    : solid;
}

.info-de-salas {
  --local--spacing: 24px;
  // Reset
  margin : 0;
  padding: 0;
  list-style-type: none;
  // Flexbox
  display        : flex;
  flex-direction : column;
  justify-content: flex-start;

  .desktop & {
    // Flexbox
    display       : flex;
    flex-direction: row;
    flex-wrap     : wrap;
    // Box Model
    padding-inline-start: var(--local--spacing);
  }
}

.info-de-sala {
  // Box Model
  margin-block-end: calc(var(--local--spacing) * (4/3));

  .desktop & {
    // Box Model
    margin-inline-end: var(--local--spacing);
  }
}

.card--info-de-sala {
  .info-de-salas-page & {
    // Box Model
    min-height: 360px; // Size of card + 'View Details' controls
    // Other
    background-color: white;
    border-radius   : 4px;
    box-shadow      : 0 8px 16px 0 #0002;
    overflow        : hidden;

    .desktop & {
      // Box Model
      width: 360px;
    }
  }
}

.layout--image-wrapper {
  .info-de-salas-page & {
    // Flexbox
    display        : flex;
    justify-content: center;
    align-items    : center;
    // Box Model
    height  : 240px;
    overflow: hidden;
  }
}

.info-de-sala__image {
  .info-de-salas-page & {
    // Box Model
    min-width : 100%;
    min-height: 100%;
  }
}

.info-de-sala__detalle {
  .info-de-salas-page & {
    // Box Model
    padding: 16px;
  }
}

.layout--detalles {
  .info-de-salas-page & {
    --control-width: 100px;

    // Flexbox
    display       : flex;
    flex-direction: row;
    align-items   : center;

    .layout--column:first-child {
      // Flexbox
      flex: 1;
      // Box Model
      max-width: calc(100% - var(--control-width)); // Accomodating controls
    }
  }
}

.info-de-sala__nombre {
  .info-de-salas-page & {
    // Box Model
    margin       : 0;
    max-width    : 95%;
    padding-block: 13px 0; // Visual positioning to align the baseline with the control
    // Making the content one line
    overflow     : hidden;
    text-overflow: ellipsis;
    white-space  : nowrap;
    // Typography
    font-family: Satoshi, sans-serif;
    font-size  : 20px;
    font-weight: 700;
    line-height: 20px;
  }
}

.wrapper--info-de-sala {
  --font-size  : 15px;
  --line-height: 15px;
  --height: var(--line-height);

  .info-de-salas-page & {
    // Box Model
    height   : var(--height);
    max-width: 95%;
    overflow : hidden;
    // Typography
    font-size  : var(--font-size);
    line-height: var(--line-height);
  }
}

.info-de-sala__costo,
.info-de-sala__capacidad {
  .info-de-salas-page & {
    // Box Model
    display: inline;
    // Typography
    color      : #666;
    font-size  : var(--font-size);
    line-height: var(--line-height);
  }
}

.info-de-sala__costo {
  $spacing: 8px;

  .info-de-salas-page & {
    // Position
    position: relative; // Positioning context
    // Box Model
    margin-inline-end: $spacing;

    &::after {
      content: '';
      // Position
      position: relative;
      top: -2px;
      // Box Model
      display: inline-block;
      height : 5px;
      width  : 5px;
      margin-inline-start: $spacing;
      // Other
      background-color: $color--attention;
      transform       : rotate(45deg);
    }
  }
}

.control--elegir-sala {
  .info-de-salas-page & {
    // Note: Height = padding x 2 (28px) + line-height (18px) = 46px
    // Box Model
    padding-block: 11px;
    width        : var(--control-width);
    // Typography
    text-align: center;
    // Making button one line max
    white-space  : nowrap;
    text-overflow: ellipsis;
    overflow     : hidden;
    // Other
    border-radius: 23px;
  }
}

.info-de-sala__features {
  .info-de-salas-page & {
    // Flexbox
    display: flex;
    flex-direction: column;
    // Other
    box-shadow: 0 -1px 1px 0 #0003;
  }
}

.wrapper--features-de-la-sala {
  // Flexbox
  flex: 1;
  // Box Model
  max-height: 0;
  overflow  : hidden;

  &.is--unfurled {
    // Box Model
    max-height: fit-content;
  }
}

.features-de-la-sala {
  // Reset
  margin : 0;
  padding: 0;
  list-style-type: none;
  // Box Model
  margin-block: 12px 8px;
  padding-inline-start: 16px;
}

.feature-de-la-sala {
  &::before {
    content: '';
    // Box Model
    display: inline-block;
    width  : 0;
    height : 0;
    margin-inline-end: 8px;
    // Other
    // Adding triangles through border styles
    border-style: solid;
    border-width: 5px 0 5px 10px;
    border-color: transparent transparent transparent $color--secondary;
    -webkit-transform:rotate(360deg)
  }
}

.wrapper--mostrar-features {
  // Flexbox
  flex: 1;
}

.control--mostrar-features {
  .info-de-salas-page & {
    // Reset
    margin    : 0;
    padding   : 0;
    background: none;
    border    : none;
    outline   : none;
    // Box Model
    width  : 100%;
    padding: 12px;
    // Typography
    color      : $color--attention;
    font-family: 'Rubik', sans-serif;
    font-size  : 14px;
    line-height: 15px;
    text-align : center;
  }
}

.selector-chevron {
  .control--mostrar-features & {
    // Box Model
    margin-inline-start: 8px;
    transform: rotate(-90deg);

    &.is--reversed {
      transform: rotate(90deg);
    }
  }
}